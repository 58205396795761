// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-messager-js": () => import("./../src/pages/messager.js" /* webpackChunkName: "component---src-pages-messager-js" */),
  "component---src-pages-notes-js": () => import("./../src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-scheduler-js": () => import("./../src/pages/scheduler.js" /* webpackChunkName: "component---src-pages-scheduler-js" */),
  "component---src-pages-sleep-tracker-js": () => import("./../src/pages/sleepTracker.js" /* webpackChunkName: "component---src-pages-sleep-tracker-js" */)
}

